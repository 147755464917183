.App {
  text-align: center;
}

.body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.current-weather {
  display: flex;
  flex-wrap: wrap;
  width: 98vw;
  justify-content: space-evenly;
}

.hero {
  width: 17vw;
  height: 25vh;
  border-radius: 5px;
  box-shadow: 0 2px 30px rgba(black, 0.2);
  background: darken(#eff3f9, 20%);
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  min-width: 200px;
  min-height: 400px;
  text-align: center;
}

.hero-wave {
  opacity: 0.3;
  position: absolute;
  top: 120%;
  left: 50%;
  background: white;
  width: 500px;
  height: 500px;
  margin-left: -250px;
  margin-top: -250px;
  transform-origin: 50% 48%;
  border-radius: 43%;
  animation: drift 3000ms infinite linear;
  z-index: 1;
}

.hero-wave.-three {
  animation: drift 5000ms infinite linear;
  z-index: 2 !important;
  opacity: 0.2;
}

.hero-wave.-two {
  animation: drift 7000ms infinite linear;
  opacity: 0.1;
  z-index: 3 !important;
}

.hero:after {
  content: "";
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  transform: translate3d(0, 0, 0);
}

@keyframes drift {
  from {
    transform: rotate(0deg);
  }

  from {
    transform: rotate(360deg);
  }
}

.hero-img {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
}

.hero-img img {
  width: 100px;
}

.hero-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  bottom: 0;
  width: 100%;
  height: 75%;
  z-index: 4;
}

.hero-location {
  font-weight: 800;
}

.hero-district {
  height: 50px;
  font-weight: 800;
  overflow: hidden;
}

.hero-temp-water {
  font-weight: 600;
}

.chart h2 {
  margin-bottom: 10px;
}

.table {
  width: 17vw;
  margin-top: 50px;
}

.filter {
  width: 98%;
  display: flex;
  justify-content: center;
}

.filter-table {
  text-align: center;
  justify-content: center;
  width: 49%;
}

.filter-chart {
  width: 49%;
  padding-top: 30px;
}

.doughnut {
  padding-top: 30px;
  width: 30vw;
  margin-inline: auto;
}

table {
  border-collapse: separate;
  border-spacing: 25px;
  margin-inline: auto;
}

.deck {
  width: 23.6vw;
  height: 25vh;
  border-radius: 5px;
  box-shadow: 0 2px 30px rgba(black, 0.2);
  background: darken(#eff3f9, 20%);
  position: relative;
  overflow: hidden;
  min-width: 200px;
  min-height: 400px;
  text-align: center;
}

.deck-cards {
  padding: 0;
  margin: 0 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.deck-card {
  display: flex;
  flex-direction: column;
  width: 9.5vw;
  height: 7.5vw;
  justify-content: center;
  align-items: center;
  margin: 6px;
  padding: 5px;
  -webkit-transition: 200ms ease;
  -o-transition: 200ms ease;
  transition: 200ms ease;
  border-radius: 10px;
}

.deck-card:hover {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  background: #f57c00;
  color: #222831;
  -webkit-box-shadow: 0 0 40px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 40px -5px rgba(0, 0, 0, 0.2)
}

.deck-card>.card-name {
  display: block;
  margin: 10px 0 0 0;
  text-align: center;
  font-weight: 700;
}

.deck-card>.card-value {
  display: block;
  text-align: center;
  margin: 10px 0 0 0;
}

.deck-card>.card-value-warn {
  display: block;
  text-align: center;
  margin: 10px 0 0 0;
  font-weight: 700;
  color: red;
}

.forecast {
  width: 98vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.forecast-card {
  width: 10.85%;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.4);
}

.forecast-card:hover {
  box-shadow: -1px 10px 29px 0px rgba(0, 0, 0, 0.8);
  border: #ffffff;
  border-style: solid;
  border-radius: 5px;
  border-width: 4px;
}

.forecast-card img {
  width: 60px;
}

h2,
p {
  margin: 5px;
}

@media only screen and (max-width: 1430px) {
  .hero {
    width: 35vw;
  }

  .hero-district {
    display: none;
  }

  .hero-temp {
    font-size: 60px;
  }

  .hero-temp-water {
    font-size: 35px;
  }

  .table {
    width: 40vw;
    text-align: center;
    justify-content: center;
    margin-top: 0;
  }

  table {
    font-size: 34px;
  }

  .deck {
    width: 38vw;
    height: 30vw;
    padding-top: 20px;
  }

  .deck-card {
    width: 12vw;
    height: 12vw;
    margin-inline: 20px;
    font-size: 27px;
  }

  .forecast {
    width: 90vw;
  }

  .forecast-card {
    width: 10vw;
    min-width: 120px;
  }
}

@media only screen and (max-width: 800px) {
  .current-weather {
    max-width: 100vw;
  }

  .hero {
    width: 100vw;
    margin-inline: auto;
  }

  .hero-district {
    display: none;
  }

  .hero-temp {
    font-size: 60px;
  }

  .hero-temp-water {
    font-size: 35px;
  }

  .chart {
    max-width: 97vw;
    margin-inline: auto;
  }

  .table {
    width: 100vw;
    margin-inline: auto;
    text-align: center;
    justify-content: center;
  }

  table {
    font-size: 34px;
    margin-inline: auto;
  }

  .deck {
    width: 100vw;
    height: 110vw;
    margin-inline: auto;
  }

  .deck-card {
    width: 42vw;
    height: 42vw;
    margin: 0;
    font-size: 27px;
  }

  .forecast {
    width: 99vw;
  }

  .forecast-card {
    width: 33vw;
  }
}